<h2>Register</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="firstname">First Name</label>
    <input type="text" formControlName="firstname" ngClass="{ 'is-invalid': submitted && f.firstname.errors }" />
    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
      <div *ngIf="f.firstname.errors.required">firstname is required</div>
    </div>
  </div>
  <div class="form-group">
    <label for="lastname">Last Name</label>
    <input type="text" formControlName="lastname" ngClass="{ 'is-invalid': submitted && f.lastname.errors }" />
    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
      <div *ngIf="f.lastname.errors.required">lastname is required</div>
    </div>
  </div>
  <div class="form-group">
    <label for="email">E-mail</label>
    <input type="text" formControlName="email" ngClass="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Username is required</div>
    </div>
  </div>
  <div class="form-group">
    <label for="username">Username</label>
    <input type="text" formControlName="username" ngClass="{ 'is-invalid': submitted && f.username.errors }" />
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
      <div *ngIf="f.username.errors.required">Username is required</div>
    </div>
  </div>
  <div class="form-group">
    <label for="password">Password</label>
    <input type="password" formControlName="password" ngClass="{ 'is-invalid': submitted && f.password.errors }" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Password is required</div>
    </div>
  </div>
  <mat-form-field appearance="fill">
    <mat-label for="role"> Role </mat-label>
    <mat-select formControlName="role">
      <mat-option *ngFor="let item of roleList" value="{{item.name}}">{{item.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <!--<div class="form-group">
    <label for="role">Role</label>
    <input type="text" formControlName="role" ngClass="{ 'is-invalid': submitted && f.role.errors }" />
    <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
      <div *ngIf="f.role.errors.required">role is required</div>
    </div>
  </div>-->
  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Register
    </button>
    <a routerLink="/login" class="btn btn-link">Cancel</a>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
  </div>
</form>
