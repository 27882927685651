<br />
<!--<h1>Home Menu</h1>-->
<!--<hr />-->
<br />

<div class="row">
  <div class="ml-auto col-md-4">
    <mat-card class="example-card mat-elevation-z20 cursor-pointer" *ngIf="userRole!=='BudgetPlanner'" routerLink="/MediaPlanManagement/MediaPlanMenu">
      <mat-icon class="mat-icon-150-size" aria-hidden="false" aria-label="Media Planning">trending_up</mat-icon>
      <mat-card-header>
        <mat-card-title class=""> Media Planning Management </mat-card-title>
        <!--<mat-card-subtitle>Cafe MMS</mat-card-subtitle>-->
      </mat-card-header>
<!--       <mat-card-content>
        <p>
          Here we will put some content or pre selectable data
        </p>
      </mat-card-content> -->
      <mat-card-actions align="end">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="mr-auto col-md-4">
    <mat-card class="example-card mat-elevation-z20 cursor-pointer" routerLink="/MasterDataManagement/MDMenu">
      <mat-icon class="mat-icon-150-size material-icons-outlined" aria-hidden="false" aria-label="Master Data">assignment</mat-icon>
      <mat-card-header>
        <mat-card-title> Master Data Management </mat-card-title>
        <!--<mat-card-subtitle>Cafe MMS</mat-card-subtitle>-->
      </mat-card-header>
      <!--<mat-card-content>
        <p>
          Here we will put some content or pre selectable data
        </p>
      </mat-card-content>-->
      <mat-card-actions align="end">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<br />

<div class="row">
  <div class="ml-auto col-md-4">
    <mat-card class="example-card mat-elevation-z20 cursor-pointer" routerLink="/ReportManagement/ReportMenu">
      <mat-icon class="mat-icon-150-size" aria-hidden="false" aria-label="Reporting">insert_chart_outlined</mat-icon>
      <mat-card-header>
        <mat-card-title> Reporting </mat-card-title>
        <!--<mat-card-subtitle>Cafe MMS</mat-card-subtitle>-->
      </mat-card-header>
      <!--<mat-card-content>
        <p>
          Here we will put some content or pre selectable data
        </p>
      </mat-card-content>-->
      <mat-card-actions align="end">
        <button mat-raised-button color="primary">Open Reports</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="mr-auto col-md-4">
    <mat-card *ngIf="userRole=='KeyUser' || userRole=='Manager' || userRole=='BudgetPlanner'" class="example-card mat-elevation-z20 cursor-pointer" routerLink="/PuiManagement/IncomingInvoice">
      <mat-icon class="mat-icon-150-size" aria-hidden="false" aria-label="Finance Module" >attach_money</mat-icon>
      <mat-card-header>
        <mat-card-title> Finance Module </mat-card-title>
        <!--<mat-card-subtitle>Cafe MMS</mat-card-subtitle>-->
      </mat-card-header>
      <!--<mat-card-content>
        <p>
          Here we will put some content or pre selectable data
        </p>
      </mat-card-content>-->
      <mat-card-actions align="end">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<br />

<!-- <div class="row" style="justify-content:center;">
  <div class="col-md-4">
    <mat-card *ngIf="userRole=='Manager' || userRole=='BudgetPlanner'" class="example-card mat-elevation-z20 cursor-pointer" routerLink="/IncomePlanningManagement/IncomePlanning">
      <mat-icon class="mat-icon-150-size" aria-hidden="false" aria-label="Income Planning">nat</mat-icon>
      <mat-card-header>
        <mat-card-title> Income Planning Management </mat-card-title>
        <mat-card-subtitle>Cafe MMS</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          Here we will put some content or pre selectable data
        </p>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div> -->
