import { Component } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { NavMenuService } from '../_services/nav-menu.service';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.css']
})

export class HomeMenuComponent {
  userRole: string;

  constructor(
    private navMenuService: NavMenuService,
    private authenticationService: AuthenticationService

  ) {
    this.authenticationService.currentUser.subscribe(x => this.userRole = x.role);
    this.navMenuService.setMyHeading("Home Menu");
  }
}
