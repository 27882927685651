import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '../_models/user.model';
import { NavMenuService } from '../_services/nav-menu.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isWait = false;
  isWaitLocal = false;
  userRole: string;
  currentUser: User;
  title = 'app';
  isExpanded = false;
  myHeading = 'Welcome';
  myTitle = 'Welcome';

  constructor(
    private router: Router,
    private navMenuService: NavMenuService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(res => {
      this.currentUser = res;
      if (this.currentUser) {
        this.userRole = this.currentUser.role;
      }
    });

    this.navMenuService.getIsProgressBar().subscribe(res => this.isWait = res);
    this.navMenuService.getIsProgressBarLocal().subscribe(res => this.isWaitLocal = res);

    this.navMenuService.getMyHeading().subscribe(res => this.myHeading = res);
    this.navMenuService.getMyTitle().subscribe(res => this.myTitle = res);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.navMenuService.setMyHeading("Welcome");
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    this.currentUser = null;
  }

  login() {
    this.router.navigate(['/login']);
  }
}
