<body>
  <mat-sidenav-container class="side-menu" style="min-height: 95vh">
    <mat-sidenav *ngIf="currentUser != undefined || currentUser != null"  #left position="start" mode="side" class="side-menu-content" opened="true">
      <mat-nav-list class="flex cafe-flex-col space-between">
        <mat-list-item routerLink="/" class="flex-1 size-64">
          <div class="cafe-flex-col center-center">
            <div class="cf-box-10"></div>
            <mat-icon class="mat-icon-1125-size" mat-list-icon>home</mat-icon>
            <div class="cf-box-5"></div>
            <div matLine class="font-16">Home</div>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/MediaPlanManagement/MediaPlanMenu" class="flex-1 size-64">
          <div class="cafe-flex-col center-center">
            <div class="cf-box-10"></div>
            <mat-icon class="mat-icon-1125-size" mat-list-icon>date_range</mat-icon>
            <div class="cf-box-5"></div>
            <div matLine class="font-16">Media Plan</div>
          </div>
        </mat-list-item>
        <mat-expansion-panel (opened)="changeMenuColor()" (closed)="changeMenuColor()" [class.mat-elevation-z0]="true" class="flex-1 center-center size-64">
          <mat-expansion-panel-header [ngClass]="financeOpen ? 'menu-expansion-panel-bg'  : '' " class="size-64">
            <div class="cafe-flex-col center-center">
            <mat-icon class="mat-icon-1125-size" mat-list-icon>euro_symbol</mat-icon>
            <div class="cf-box-10"></div>
            <div matLine class="font-16">Finance</div>
          </div>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a routerLink="PuiManagement/IncomingInvoice">
              <span matLine class="font-14 menu-expansion-panel padding-t-10 border-top-gray">Bejövő Számla</span>
            </a>
            <a routerLink="PuiManagement/CompletionCertificate">
              <span matLine class="font-14 menu-expansion-panel">Teljesítési igazolás</span>
            </a>
            <a routerLink="PuiManagement/PreliminaryInvoice">
              <span matLine class="font-14 menu-expansion-panel">Diszpo Számla</span>
            </a>
            <!-- <a routerLink="PuiManagement/OutgoingInvoice">
              <span matLine class="font-14 menu-expansion-panel">Kimenő Számla</span>
            </a> -->
            <a routerLink="PuiManagement/PuiAllReport">
              <span matLine class="font-14 menu-expansion-panel ">Riport - PUI</span>
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
        <mat-list-item routerLink="/ReportManagement/ReportMenu" class="flex-1 size-64">
          <div class="cafe-flex-col center-center">
            <div class="cf-box-10"></div>
            <mat-icon class="mat-icon-1125-size" mat-list-icon>assessment</mat-icon>
            <div class="cf-box-5"></div>
            <div matLine class="font-16">Reporting</div>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/MasterDataManagement/MDMenu"  class="flex-1 size-64">
          <div class="cafe-flex-col center-center">
            <div class="cf-box-10"></div>
            <mat-icon class="mat-icon-1125-size" mat-list-icon>build</mat-icon>
            <div class="cf-box-5"></div>
            <div matLine class="font-16">Master Data</div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="logout()" class="flex-1 size-64">
          <button  mat-button class="cafe-flex-col center-center">
            <div class="cf-box-10"></div>
            <mat-icon class="mat-icon-1125-size" mat-list-icon>exit_to_app</mat-icon>
            <div class="cf-box-5"></div>
            <div matLine class="font-16">Logout</div>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <div class="container bg-light-blue" style="min-height: 95vh; position: absolute; top:0" >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>
  <app-footer></app-footer>
</body>


<!-- <app-nav-menu></app-nav-menu>
<div class="container">
  <router-outlet></router-outlet>
</div> -->