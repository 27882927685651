import { Component, ContentChild, HostListener, ElementRef, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { fromEvent, Subject } from 'rxjs';
import { filter, take, switchMapTo } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EditableOnEnterDirective } from './edit-on-enter.directive';

@Component({
  selector: 'editable',
  template: '<ng-container *ngTemplateOutlet="currentView"></ng-container>',
  //'<ng-container *ngTemplateOutlet="currentView"></ng-container>',
  styleUrls: ['./editable.component.css']
})
export class EditableComponent {

  @Output() update = new EventEmitter();
 // _viewModeTpl: ViewModeDirective;
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;// set viewModeTpl(data: ViewModeDirective) { this._viewModeTpl = data; };

 // _editModeTpl: EditModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;// set editModeTpl(data: EditModeDirective) { this._editModeTpl = data; };

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();
  mode: 'view' | 'edit' = 'view';

  constructor(private host: ElementRef) {
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  toViewMode() {
    console.log("Enter");
    this.update.next();
    this.mode = 'view';
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, 'dblclick').pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.mode = 'edit';
      this.editMode.next(true);
    });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'dblclick').pipe(
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    )

    this.editMode$.pipe(
      switchMapTo(clickOutside$),
      untilDestroyed(this)
    ).subscribe(event => {
      this.update.next();
      this.mode = 'view';
    });
  }

  get currentView() {
    ////console.log(this);
    ////console.log(this.viewModeTpl.tpl);
    //return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnDestroy() {
  }
}
