import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  private currentHeading$ = new BehaviorSubject<string>('');
  private currentTitle$ = new BehaviorSubject<string>('');
  currentIsProgressBar$ = new BehaviorSubject<boolean>(false);
  currentIsProgressBarLocal$ = new BehaviorSubject<boolean>(false);

  setMyHeading(myHeading: string) {
    this.currentHeading$.next(myHeading);
  }

  setMyTitle(myTitle: string) {
    this.currentTitle$.next(myTitle);
  }

  getMyHeading(): Observable<string> {
    return this.currentHeading$.asObservable();
  }

  getMyTitle(): Observable<string> {
    return this.currentTitle$.asObservable();
  }

  setIsProgressBar(stat: boolean) {
    this.currentIsProgressBar$.next(stat);
  }

  getIsProgressBar(): Observable<boolean> {
    return this.currentIsProgressBar$;
  }

  setIsProgressBarLocal(stat: boolean) {
    this.currentIsProgressBarLocal$.next(stat);
  }

  getIsProgressBarLocal(): Observable<boolean> {
    return this.currentIsProgressBarLocal$;
  }
}
