import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user.model';


@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject('APIUrl') private APIUrl: string
  ) { }

  getAll() {
    return this.http.get<User[]>(`${this.APIUrl}/users`);
  }

  register(user: User) {
    return this.http.post(`${this.APIUrl}/users/register`, user);
  }

  delete(id: number) {
    return this.http.delete(`${this.APIUrl}/users/${id}`);
  }
}
