import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SnackProps } from '../app.component';

@Injectable({
  providedIn: 'root'
})

export class SnackBarService {
  public notification$: Subject<SnackProps> = new Subject();
}
