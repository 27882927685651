<div style="position:absolute; top:25%; left:33.33%">
  <mat-card class="mat-elevation-z20">
    <mat-card-header>
      <mat-card-title>Cafe Plan(M) - Media Management Software</mat-card-title>
      <mat-card-subtitle>Login</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label for="username">Username</mat-label>
          <input matInput type="text" formControlName="username" />
        </mat-form-field>
        <br />
        <mat-form-field appearance="fill" class="full-width">
          <mat-label for="password">Password</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>
        <br />
        <button mat-raised-button color="primary" [disabled]="loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Login
        </button>
        <!--<a routerLink="/register" class="btn btn-link">Register</a>-->
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </mat-card-content>
</mat-card>
</div>
