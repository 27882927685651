import { Component } from '@angular/core';
import { User } from './_models/user.model';
import { AuthenticationService } from './_services/authentication.service';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBarService } from './_services/snack-bar.service';
import { NavMenuService } from './_services/nav-menu.service';
import { style } from '@angular/animations';
import { element } from 'protractor';

export interface SnackProps {
  text: string;
  type?: SnackType;
  duration?: number;
}

export interface SnackType {
  name?: string;
  duration?: number;
  horposition?: MatSnackBarHorizontalPosition;
  vertposition?: MatSnackBarVerticalPosition;
  action: string;
  panelClass?: string;
}

@Component({
  selector: 'app',
  templateUrl: './app.component.html',

})

export class AppComponent {
  // Sets initial value to true to show loading spinner on first load
  loading = true;
  currentUser: User;
  financeOpen: boolean = false;
  title = 'app';
  


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
    private snackBar: MatSnackBar,
    private navMenuService: NavMenuService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);

    });

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.snackBarService.notification$.subscribe(message => {
      if (message.type) {
        this.snackBar.open(message.text, message.type.action, {
          duration: message.duration ? message.duration: message.type.duration,
          verticalPosition: message.type.vertposition,
          horizontalPosition: message.type.horposition,
          //panelClass: 'blue-snackbar'
        });
      } else {
        this.snackBar.open(message.text, 'OK', {
          duration: 7000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          //panelClass: 'blue-snackbar'
        });
      }
      
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navMenuService.setIsProgressBar(true);
    }
    if (event instanceof NavigationEnd) {
      this.navMenuService.setIsProgressBar(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.navMenuService.setIsProgressBar(false);
    }
    if (event instanceof NavigationError) {
      this.navMenuService.setIsProgressBar(false);
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  changeMenuColor() {
    this.financeOpen = !this.financeOpen
    
  }

  

}
