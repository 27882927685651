import { NgModule, Inject, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { DomSanitizer } from '@angular/platform-browser';
import { EditableComponent } from './editable/editable.component';
import { ViewModeDirective } from './editable/view-mode.directive';
import { EditModeDirective } from './editable/edit-mode.directive';
import { FocusableDirective } from './focusable.directive';
import { EditableOnEnterDirective } from './editable/edit-on-enter.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS
} from "@angular/material-moment-adapter";
import 'moment/locale/hu';

@NgModule({
  declarations: [EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatToolbarModule,
    MatStepperModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    DragDropModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDatepickerModule, MatMomentDateModule,
    MatNativeDateModule,
    MatExpansionModule,
    CommonModule,
    MatListModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule
  
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'hu'},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatStepperModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    DragDropModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTreeModule,
    MatTabsModule,
    MatSortModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    MatListModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule
    ],
})
export class MaterialModule { 
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, @Inject('APIUrl') private APIUrl: string, @Inject('assetUrl') private assetUrl: string) {
    iconRegistry.addSvgIcon('excel_icon', sanitizer.bypassSecurityTrustResourceUrl(`${this.assetUrl}/assets/iconfinder_excel_272697.svg`));
  }
}
