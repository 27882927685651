import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    console.log("ROUTE STATE: " + state);
    console.log("ROUTE: " + route);
    if (currentUser) {
      if (currentUser.role == "KeyUser") {
        return true;
      }
      if (route.url.toString() == "MDMenu") {
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        alert("You are not authorized to use Master Data Module, please contact Kitti Solymos for Master Data changes!")
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  
}
