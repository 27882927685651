import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';

export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)

      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.status === 401) {
            // auto logout if 401 response returned from api
            this.authenticationService.logout();
            location.reload();
            errorMessage = `Error: ${error.error.message}`;
          }
          //else if (error.status === 200) {
          //  // client-side error
          //  //errorMessage = `Error: ${error.error.message}`;
          //  return ok;
          //}
          else if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          
          window.alert(errorMessage);
          return throwError(errorMessage);
        })
      )
  }
}
