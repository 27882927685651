<header>
  <nav>
    <mat-toolbar style=" background-color: palevioletred; min-height: 48px !important; height: 48px !important;">
      <mat-toolbar-row style="min-height: 48px !important; height: 48px !important;">
        <button mat-icon-button routerLink="/" matTooltip="Home" aria-label="">
          <mat-icon class="mat-icon-150-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Navigate Home" routerLink="/">home</mat-icon>
          <span style="font-size:large; padding-left:.5rem;">Café Plan(M)</span>
        </button>
        <span class="fill-remaining-space-medium"></span>{{myHeading}}{{myTitle}}
        <span class="fill-remaining-space"></span>

        <mat-icon *ngIf="userRole=='BudgetPlanner' || userRole=='Manager'" class="mat-icon-150-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Income Planning" matTooltip="Income Planning" routerLink="/IncomePlanningManagement/IncomePlanning">nat</mat-icon>
        <span *ngIf="userRole=='BudgetPlanner' || userRole=='Manager'" class="fill-remaining-space-small"></span>

        <mat-icon *ngIf="userRole=='KeyUser' || userRole=='Manager'" class="mat-icon-1125-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Exchange Rate" matTooltip="Exchange Rate" routerLink="/MasterDataManagement/ExchangeRate">euro</mat-icon>
        <span *ngIf="userRole=='KeyUser' || userRole=='Manager'" class="fill-remaining-space-small"></span>

        <mat-icon *ngIf="userRole=='KeyUser' || userRole=='BudgetPlanner' || userRole=='Manager'" class="mat-icon-150-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Finance Module" matTooltip="Finance Module" routerLink="/PuiManagement/PuiMenu">attach_money</mat-icon>
        <span *ngIf="userRole=='KeyUser' || userRole=='BudgetPlanner' || userRole=='Manager'" class="fill-remaining-space-small"></span>

        <mat-icon *ngIf="userRole!=='BudgetPlanner'" class="mat-icon-150-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Media Planning" matTooltip="Media Planning" routerLink="/MediaPlanManagement/MediaPlanMenu">trending_up</mat-icon>
        <span class="fill-remaining-space-small"></span>

        <mat-icon class="mat-icon-150-size cursor-pointer material-icons-outlined" aria-hidden="false" aria-label="Master Data" matTooltip="Master Data" routerLink="/MasterDataManagement/MDMenu">assignment</mat-icon>
        <span class="fill-remaining-space-small"></span>

        <mat-icon class="mat-icon-150-size cursor-pointer" aria-hidden="false" aria-label="Reporting" matTooltip="Reporting" routerLink="/ReportManagement/ReportMenu">insert_chart_outlined</mat-icon>
        <span class="fill-remaining-space-medium"></span>

        <button mat-icon-button [matMenuTriggerFor]="account" aria-label="">
          <mat-icon class="mat-icon-150-size cursor-pointer material-icons-outlined" matTooltip="User Account">account_box</mat-icon>
        </button>
        <mat-menu (click)="login()" #account="matMenu">
          <button *ngIf="currentUser" mat-menu-item disabled>
            <mat-icon>arrow_forward</mat-icon>
            <span>Login</span>
          </button>
          <button (click)="logout()" *ngIf="currentUser" mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
          <button (click)="login()" *ngIf="!currentUser" mat-menu-item>
            <mat-icon>arrow_forward</mat-icon>
            <span>Login</span>
          </button>
          <button (click)="logout()" *ngIf="!currentUser" mat-menu-item disabled>
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
    <mat-progress-bar mode="indeterminate" *ngIf="isWaitLocal"></mat-progress-bar>
  </nav>
  </header>
