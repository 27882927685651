import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_services/user.service';
import { first } from 'rxjs/operators';
import { NavMenuService } from '@/_services/nav-menu.service';
import { SnackBarService } from '@/_services/snack-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@/_models/user.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: UntypedFormGroup;
  error: any;
  loading = false;
  submitted = false;
  roleList = [{ id: 1, name: 'MediaPlanner' }, { id: 2, name: 'BudgetPlanner' }, { id: 3, name: 'KeyUser' }, { id: 4, name: 'Manager' }, { id: 4, name: 'TeamLeader' }];
  currentUser: User;
//MediaPlanner: create/edit media planning; Export reports
//BudgetPlanner: create / edit budget planning;Export reports
    //Kérdés: A BudgetPlanner láthassa a Media terveket ?
//Manager : MediaPlanner + BudgetPlanner + KeyUser
//KeyUser: MediaPlanner + MasterData
//TeamLeader: MediaPlanner with extended actions(ex.: delete)

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService,
    private navMenuService: NavMenuService,
    private snackBarService: SnackBarService,
    private snackBar: MatSnackBar,
  ) {
    this.snackBarService.notification$.subscribe(message => {
      if (message.type) {
        this.snackBar.open(message.text, message.type.action, {
          duration: message.duration ? message.duration: message.type.duration,
          verticalPosition: message.type.vertposition,
          horizontalPosition: message.type.horposition,
          //panelClass: 'blue-snackbar'
        });
      } else {
        this.snackBar.open(message.text, 'OK', {
          duration: 7000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          //panelClass: 'blue-snackbar'
        });}
     });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      role: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;


    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/login']);
        },
        error => {
          this.loading = false;
        });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.navMenuService.setIsProgressBar(true);
    }
    if (event instanceof NavigationEnd) {
      this.navMenuService.setIsProgressBar(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.navMenuService.setIsProgressBar(false);
    }
    if (event instanceof NavigationError) {
      this.navMenuService.setIsProgressBar(false);
    }
  }
}
