import { BrowserModule } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { AuthGuard } from './_guard/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MaterialModule } from './material.module';
import { HomeMenuComponent } from './home-menu/home-menu.component';
import { AuthenticationService } from './_services/authentication.service';
import { HighlightDirective } from './highlight.directive';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    LoginComponent,
    RegisterComponent,
    HomeMenuComponent,
    HighlightDirective,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
      { path: 'MasterDataManagement', loadChildren: () => import('./master-data-management/master-data-management.module').then(m => m.MasterDataManagementModule) },
      { path: 'ExchangeRate', loadChildren: () => import('./master-data-management/master-data-management.module').then(m => m.MasterDataManagementModule) },
      { path: 'IncomePlanningManagement', loadChildren: () => import('./income-planning-management/income-planning-management.module').then(m => m.IncomePlanningManagementModule) },
      { path: 'ReportManagement', loadChildren: () => import('./report-management/report-management.module').then(m => m.ReportManagementModule) },
      { path: 'MediaPlanManagement', loadChildren: () => import('./media-plan-management/media-plan-management.module').then(m => m.MediaPlanManagementModule) },
      { path: 'PuiManagement', loadChildren: () => import('./pui/pui.module').then(m => m.PuiModule) },
      { path: '**', component: HomeMenuComponent, canActivate: [AuthGuard], redirectTo: '' }
    ]),
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [AuthenticationService] },
    { provide: 'APIUrl', useValue: environment.apiUrl },
    { provide: 'assetUrl', useValue: environment.assetUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { };
